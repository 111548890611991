import React from "react"
import styled from "styled-components"
import LinkWrapper from "../../utils/linkWrapper"

// create a list of links for the pages search result hits
const SearchPageHitsList = ({
  pages,
  className = "",
  client,
  searchQuery,
  searchResponse,
}) => {
  if (!Array.isArray(pages) || !pages.length) {
    return null
  }
  return (
    <div className={className}>
      {pages.map((page, index) => {
        const pageSlug = page?.permalink?.raw || page?.permalink
        const pageTitle = page?.title?.raw || page?.title
        return (
          <StyledLinkWrapper
            to={pageSlug}
            key={index}
            onClick={() => {
              if (searchResponse?.info?.meta?.request_id) {
                const searchAnalyticsObject = {
                  query: searchQuery,
                  documentId: page.id.raw,
                  requestId: searchResponse?.info?.meta?.request_id,
                }
                client.click(searchAnalyticsObject).catch((error) => {
                  console.error(`search click error: ${error}`)
                })
              }
            }}
          >
            <h4 dangerouslySetInnerHTML={{ __html: pageTitle }} />
          </StyledLinkWrapper>
        )
      })}
    </div>
  )
}

export default SearchPageHitsList

// ==========
// 	 STYLES
// ==========
const StyledLinkWrapper = styled(LinkWrapper)`
  display: block;
  padding: 10px 20px;
  border-bottom: 1px dashed #dee3e8;
  &:first-child {
    border-top: 1px dashed #dee3e8;
  }
  &:hover {
    box-shadow: 0 0 25px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 5;
  }
`
