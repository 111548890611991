import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import SvgIcon from "../../atoms/icons/svg-icon"
import Calendar from "../../atoms/icons/files/calendar.jsx"
import ArrowRight from "../../atoms/icons/files/arrow-right.jsx"
import { formatDateStr, path, createExcerpt } from "../../../lib/util"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"

const BlogCard = ({ post, searchAnalyticsFunction }) => {
  const {
    allFile: { nodes },
    favicon,
  } = useStaticQuery(imageQuery)

  const randomIndex = Math.floor(Math.random() * 5)
  const randomFallback = nodes[randomIndex]
  const fallBackFluid = randomFallback.childImageSharp.gatsbyImageData

  const postFluid = path(
    [
      "featuredImage",
      "imageFile",
      "localFile",
      "childImageSharp",
      "gatsbyImageData",
    ],
    post
  )
  const faviconFixed = path(["childImageSharp", "gatsbyImageData"], favicon)

  const { content, excerpt: ex } = post
  const excerpt = createExcerpt(ex, content)

  return (
    <Container>
      <Media
        image={postFluid || fallBackFluid}
        key={randomIndex}
        alt={post.slug}
      />
      <Content>
        <Link to={"/sleep-matters/" + post.slug}>
          <Title dangerouslySetInnerHTML={{ __html: post.title }} />
        </Link>
        <Meta>
          <Date>
            <SvgIcon SvgComponent={Calendar} hue="tusk" shade="080" />
            <span>{formatDateStr(post.date)}</span>
          </Date>
          <Author>
            {faviconFixed && (
              <GatsbyImage image={faviconFixed} alt="blog author avatar" />
            )}
            <span>{"The Mattress Warehouse"}</span>
          </Author>
        </Meta>
        <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />
        <Button
          to={"/sleep-matters/" + post.slug}
          onClick={searchAnalyticsFunction}
        >
          <div>READ MORE</div>
          <SvgIcon SvgComponent={ArrowRight} hue="danger" shade="fontColor" />
        </Button>
      </Content>
    </Container>
  )
}

const Meta = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Date = styled.div`
  color: #9ba5ae;
  margin-right: 30px;
  .icon {
    margin-right: 10px;
  }
`

const Author = styled.div`
  color: #9ba5ae;
  display: flex;
  align-items: center;
  > div {
    margin-right: 10px;
    border-radius: 200px;
    width: 20px;
    height: 20px;
  }
`

const imageQuery = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "placeholders" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    favicon: file(relativePath: { eq: "FaviconBed.png" }) {
      childImageSharp {
        gatsbyImageData(width: 20, height: 20, layout: FIXED)
      }
    }
  }
`

export default BlogCard

const Container = styled.div`
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  padding: 10px;
  display: grid;
  grid-template-columns: 3fr 4fr;
  border-radius: 2px;
  grid-gap: 20px;
  margin-bottom: 30px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    grid-template-columns: 1fr;
  }
`
const Media = styled(GatsbyImage)`
  min-height: 160px;
  & > div {
    padding-bottom: 0 !important;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > * {
    margin: 5px 0;
  }
`
const Title = styled.h5`
  color: #223247;
  font-size: 25px;
  font-weight: 400;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 20px;
  }
`
const Excerpt = styled.div`
  color: #223247;
  font-size: 16px;
`

const Button = styled(Link)`
  border-radius: 3px;
  overflow: hidden;
  background-color: #223247;
  color: white;
  display: flex;
  width: 200px;
  justify-content: space-between;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(3px);
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: 100%;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > span {
    padding: 10px;
    background: #162232;
  }
`
